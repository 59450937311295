<template>
  <div class="main-box" v-if="shopDetail" @click.stop="closemodel">
    <a-breadcrumb>
      <a-breadcrumb-item
        ><router-link to="/knowledgeMall/shopHome"
          >书课专区</router-link
        ></a-breadcrumb-item
      >
      <a-breadcrumb-item v-if="$route.query.name"
        ><a @click="$router.go(-1)">{{
          $route.query.name
        }}</a></a-breadcrumb-item
      >
      <a-breadcrumb-item style="color: #15b7dd">{{
        shopDetail.courseName
      }}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="content">
      <div class="shopimg">
        <img class="img" :src="imgSrc" alt="" />
        <!-- 商品 -->
        <!-- <div class="imgList" v-if="$route.query.productId && shopDetail.photoList && shopDetail.photoList.length > 1">
          <img :class="{active:imgSrc == item.photo}" :src="item.photo" v-for="(item,index) in shopDetail.photoList" @click="imgSrc = item.photo" :key="index" alt="">
        </div> -->
      </div>
      <div class="shopDetail">
        <p class="name" v-if="$route.query.productId">{{ shopDetail.courseName }}</p>
        <p class="describe">
          {{ shopDetail.categoryName }} | 视频数量：{{ shopDetail.classHour }}个
        </p>
        <div
          class="shopMessage"
          :style="{ backgroundImage: 'url(' + imgSrc1 + ')' }"
        >
          <div class="price">
            <p class="title">价格：</p>
            <!-- 商品 -->
            <p
              class="new"
              :class="{ newFree: !shopDetail.couponPrice }"
              v-if="$route.query.productId && !!shopDetail.couponPrice"
            >
              ￥<span v-if="shopDetail.couponPrice"
                >{{ managePrice(shopDetail.couponPrice * 1)[0]
                }}<span class="new_point"
                  >.{{ managePrice(shopDetail.couponPrice * 1)[1] }}</span
                ></span
              >
              <span v-else>免费</span>
            </p>
            <p
              class="new"
              :class="{ newFree: !shopDetail.couponPrice }"
              v-if="$route.query.productId && !shopDetail.couponPrice"
            >
              免费
            </p>
            <p class="old" v-if="$route.query.productId && shopDetail.original && shopDetail.couponPrice">
              {{
                shopDetail.originalPrice
                  ? "￥" + (shopDetail.originalPrice * 1).toFixed(2)
                  : "免费"
              }}
            </p>
          </div>
          <div
            class="navs"
            v-if="couponList && couponList.length && shopDetail.couponPrice"
          >
            <span class="title">优惠券：</span>
            <span
              class="nav"
              v-for="(ite, ind) in couponList"
              :key="ind"
              v-show="ind < 4"
              >{{
                ite.type == 1
                  ? "满" + ite.fullPrice + "减" + ite.couponPrice
                  : ite.type == 3
                  ? "满" +
                    ite.discountPrice +
                    (ite.discountType == 1 ? "件" : "元") +
                    ite.discount +
                    "折"
                  : ""
              }}</span
            >
            <span
              class="button"
              @click.stop="couponFlag = !couponFlag"
              >领券<a-icon class="icon" type="down" v-if="!couponFlag" /><a-icon class="icon" v-else type="up" />
            </span>
            <div class="couponList" v-show="couponFlag">
              <div class="coupon" :class="{geted:ite.drawId}" v-for="(ite, ind) in couponList" :key="ind">
                <div class="couponLeft">
                  <!-- 满减券 -->
                  <p class="price" v-if="ite.type == 1">￥
                    <span 
                      :style="[
                        {
                          fontSize:(ite.couponPrice+'').length < 4?'30px':(ite.couponPrice+'').length == 4?'24px':'18px'
                        },
                        {
                          lineHeight:(ite.couponPrice+'').length <= 4?'45px':'37px'
                        }
                      ]
                      ">{{ite.couponPrice}}</span></p>
                  <!-- 折扣券 -->
                  <p class="price" v-else><span>{{ite.discount}}</span>折</p>
                  <p class="condition">{{ite.type == 1?('满'+ite.fullPrice):ite.type == 3?('满'+ite.discountPrice+(ite.discountType==1?'件':'元')):''}}可用</p>
                </div>
                <div class="couponRight">
                  <p class="couponName mallHidden">{{ite.name}}</p>
                  <p class="useTime">{{useTime(ite)}}</p>
                  <p class="scope mallHidden">【{{ite.receiveDesc}}】</p>
                </div>
                <div v-if="!ite.drawId" @click="receiveCoupon(ite)" class="couponBtn">领取</div>
                <div v-else class="couponStyle">已领取</div>
              </div>
            </div>
          </div>
          <div
            class="giftList"
            v-if="
              $route.query.productId &&
              seriesproductList &&
              seriesproductList.length
            "
          >
            <p class="title">赠品：</p>
            <!-- <div
              class="giftItem"
              v-for="(item, index) in seriesproductList"
              :key="index"
            >
              <img :src="item.listPhoto" alt="" />
              <div class="message">
                <p class="giftName onlyOneHidden">{{ item.name }}</p>
                <p class="count">x1</p>
              </div>
            </div> -->
            <div
              class="giftItem"
              :class="{ giftItemSwiper: seriesproductList.length >= 6 }"
            >
              <swiper ref="mySwiper" :options="swiperOptions">
                <swiper-slide
                  v-for="(item, index) in seriesproductList"
                  :key="index"
                  @click.native="goShopDetail(item)"
                >
                  <img :src="item.listPhoto" alt="" />
                  <div class="message">
                    <p class="giftName onlyOneHidden">{{ item.name }}</p>
                    <p class="count">x1</p>
                  </div>
                </swiper-slide>
                <!-- 前进后退按钮 -->
                <div
                  v-if="seriesproductList.length >= 6"
                  class="swiper-button-prev swiper_btn swiper-button-white"
                  slot="button-prev"
                >
                  <a-icon class="icon" type="left" />
                </div>
                <div
                  v-if="seriesproductList.length >= 6"
                  class="swiper-button-next swiper_btn swiper-button-white"
                  slot="button-next"
                >
                  <a-icon class="icon" type="right" />
                </div>
              </swiper>
            </div>
          </div>
          <!-- 会员权益 -->
          <div class="equity" v-if="shopDetail.kfxhMumber">
            <p class="title">会员权益：</p>
            购买可成为康复协会会员，查看<a @click="isEquityShow=true">《会员权益》</a>
          </div>
          <div class="studyNum" v-if="$route.query.productId">
            <p class="num">{{ shopDetail.studyNum }}人已学习</p>
            <!-- <p class="font"></p> -->
          </div>
        </div>
        <p
          v-if="$route.query.productId"
          class="introduce"
          v-html="shopDetail.introduction"
        ></p>
        <div
          class="classDate"
          v-if="$route.query.productId && classList.length"
        >
          <p class="title">选择期数：</p>
          <div class="classList">
            <div
              class="classItem"
              :style="{
                'border-color':
                  openTimeId == ite.openTimeId ? '#15B7DD' : '#EBEBEB',
              }"
              @click="selectOpenTimeId(ite)"
              v-for="(ite, ind) in classList"
              :key="ind"
              v-show="mallClass || ind < 3"
            >
              <p class="classname">
                <span>{{ ite.className }}</span
                ><span
                  >剩余：<span style="color: #15b7dd">{{
                    ite.currentNumber
                  }}</span></span
                >
              </p>
              <p class="time">{{ ite.startTime }}-{{ ite.endTime }}</p>
            </div>
          </div>
        </div>
        <p
          class="showAll"
          v-if="$route.query.productId && classList.length > 3"
        >
          <span @click="mallClass = !mallClass"
            >{{ mallClass ? "收起" : "更多期数"
            }}<a-icon
              class="icon"
              :style="{
                transform: mallClass ? 'rotate(-90deg)' : 'rotate(90deg)',
              }"
              type="double-right"
          /></span>
        </p>
        <!-- 线下培训地点 -->
        <!-- 会员权益 -->
          <div class="classDate" v-if="shopDetail.province&&shopDetail.city">
            <p class="title">培训地点：</p>
            <p class="teacherName" >
              <img
                width="11"
                height="14"
                src="@/assets/image/index/roup979.png"
                alt=""
              />
              {{ shopDetail.province }} {{ shopDetail.city }}{{ shopDetail.district?' | ' + shopDetail.district:'' }} {{shopDetail.address}}
            </p>
          </div>
       
        <div class="classDate" v-if="productList.length">
          <p class="title">相关商品：</p>
          <div class="classList">
            <a-carousel arrows :infinite="false" :dots="false" v-if="clientWidth>=16000">
              <div
                slot="prevArrow"
                class="custom-slick-arrow"
                style="top: 10px; left: 0; z-index: 1"
                v-show="productList.length > 2"
              >
                <a-icon type="left" />
              </div>
              <div
                slot="nextArrow"
                class="custom-slick-arrow"
                style="top: 10px; right: 0"
                v-show="productList.length > 2"
              >
                <a-icon type="right" />
              </div>
              <div
                class="carouselList"
                :class="{'carouselListCenter': productList.length <= 2}"
                v-for="(item, index) in Math.ceil(productList.length / 2)"
                :key="index"
              >
                <div
                  class="item noprev"
                  :style="{cursor:shopDetail.have?'pointer':''}"
                  v-for="(ite, ind) in productList.slice(
                    index * 2,
                    index * 2 + 2
                  )"
                  :key="ind"
                >
                  <img class="img" :src="ite.picture" alt="" @click="goShopDetail(ite,true)"/>
                  <img
                    class="checked"
                    v-if="ite.checked && !shopDetail.have"
                    @click="$set(ite, 'checked', false)"
                    src="@/assets/image/knowledgeMall/checked.png"
                    alt=""
                  />
                  <img
                    class="checked"
                    v-if="!ite.checked && !shopDetail.have"
                    @click="$set(ite, 'checked', true)"
                    src="@/assets/image/knowledgeMall/unChecked.png"
                    alt=""
                  />
                  <div class="right" @click="goShopDetail(ite,true)">
                    <p class="produceName onlyOneHidden">{{ ite.name }}</p>
                    <div class="bottom">
                      <p class="price">
                        {{ ite.price ? "￥" + ite.price.toFixed(2) : "免费" }}
                      </p>
                      <div class="count" v-if="!shopDetail.have">
                        <a-icon
                          class="icon"
                          type="minus"
                          :style="{color: ite.productType == 1?'#cccccc':'#666666'}"
                          @click.stop="changeCount(ite, -1)"
                        />
                        <a-input-number
                          id="inputNumber"
                          v-model="ite.count"
                          :min="1"
                          :max="ite.productType == 1?1:Infinity"
                        />
                        <a-icon
                          class="icon"
                          type="plus"
                          :style="{color: ite.productType == 1?'#cccccc':'#666666'}"
                          @click.stop="changeCount(ite, 1)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a-carousel>
            <div
              v-show="clientWidth<16000 && productList.length > 1"
              slot="prevArrow"
              class="custom-slick-arrow swiper-product-next"
              style="top: 10px; left: 0; z-index: 1"
            >
              <a-icon type="left" />
            </div>
            <swiper class="carouselList" :style="{width:productList.length > 1?'510px!important':'580px!important'}" ref="mySwiper" :options="productSwiperOptions" v-if="clientWidth<16000">
              <swiper-slide v-for="(ite, ind) in productList" :key="ind">
                <div class="item" :class="{noprev:productList.length == 1}">
                  <img class="img" :src="ite.picture" alt="" @click="goShopDetail(ite,true)" />
                  <img
                    class="checked"
                    v-if="ite.checked"
                    @click="$set(ite, 'checked', false)"
                    src="@/assets/image/knowledgeMall/checked.png"
                    alt=""
                  />
                  <img
                    class="checked"
                    v-if="!ite.checked"
                    @click="$set(ite, 'checked', true)"
                    src="@/assets/image/knowledgeMall/unChecked.png"
                    alt=""
                  />
                  <div class="right" @click="goShopDetail(ite,true)">
                    <p class="produceName onlyOneHidden">{{ ite.name }}</p>
                    <div class="bottom">
                      <p class="price">
                        {{ ite.price ? "￥" + ite.price.toFixed(2) : "免费" }}
                      </p>
                      <div class="count">
                        <a-icon
                          class="icon"
                          type="minus"
                          :style="{color: ite.productType == 1?'#cccccc':'#666666'}"
                          @click.stop="changeCount(ite, -1)"
                        />
                        <a-input-number
                          id="inputNumber"
                          v-model="ite.count"
                          :min="1"
                          :max="ite.productType == 1?1:Infinity"
                        />
                        <a-icon
                          class="icon"
                          type="plus"
                          :style="{color: ite.productType == 1?'#cccccc':'#666666'}"
                          @click.stop="changeCount(ite, 1)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
            <div
              v-show="clientWidth<16000 && productList.length > 1"
              slot="nextArrow"
              class="custom-slick-arrow swiper-product-prev"
              style="top: 10px; right: 0"
            >
              <a-icon type="right" />
            </div>
          </div>
        </div>
        <!-- <div
          class="buybutton"
          @click="
            (visible = true),
              (fontText = shopDetail.couponPrice ? '系统升级，请下载华夏云课堂app进行购买' : '系统升级，请下载华夏云课堂app进行学习')
          "
        >
          <a-icon type="shopping" />
          <p>{{ !!shopDetail.couponPrice ? "立即购买" : "加入学习" }}</p> -->
        <!-- </div> -->

        <div
          class="step-num"
          v-if="shopDetail.buyLimitNum == 1"
        >
          <span>数量：</span>
          <div class="count">
            <a-icon class="icon" type="minus" @click="changeCourseNum(-1)" />
            <a-input-number
              id="inputNumber"
              v-model="courseNum"
              :min="1"
            />
            <a-icon class="icon" type="plus" @click="changeCourseNum(1)" />
          </div>
        </div>

        <!-- 购买须知 -->
        <a-radio class="bayNote" :checked="prebuyNoticeFlag" @change="prebuyNoticeFlag = true" v-if="(!shopDetail.have || shopDetail.buyLimitNum) && shopDetail.prebuyNotice">我已仔细阅读并了解该 <span @click.prevent="prebuyNoticeVisible = true" class="funcBtn">《购买须知》</span></a-radio>

        <div class="buyBtns" v-if="shopDetail.have && shopDetail.courseClass == 2 && !shopDetail.hasVideoFlag" >
            <p class="buybuttond" @click="$message.warning('您已报名该培训，请根据培训时间参加培训。')">已报名</p> 
        </div>
        <div class="buyBtns" v-else>
          <div
            v-if="(!!shopDetail.couponPrice && !shopDetail.have) || (shopDetail.buyLimitNum == 1)"
            class="buybutton"
            @click="joinShopCar"
          >
            <a-icon type="shopping-cart" />
            <p>加入购物车</p>
          </div>
          <div
            v-if="!!shopDetail.couponPrice"
            class="buybutton"
            :class="shopDetail.have && false?'isBuy':''"
            @click="buyNow"
          >
            <a-icon v-if="!shopDetail.have" type="shopping" />
            <p v-if="shopDetail.have">
              {{ shopDetail.buyLimitNum?'再次购买':shopDetail.isOpenClass == 1 ? "待开课" : "去学习" }}
            </p>
            <p v-else>立即购买</p>
          </div>
          <div
            v-else
            class="buybutton"
            :class="shopDetail.have && false?'isBuy':''"
            @click="joinStudy"
          >
            <a-icon v-if="!shopDetail.have" type="shopping" />
            <p v-if="shopDetail.have">
              {{ shopDetail.isOpenClass == 1 ? "待开课" : "去学习" }}
            </p>
            <p v-else>{{shopDetail.have && false?'已':''}}加入学习</p>
          </div>
         
        </div>
      </div>
    </div>
    <!-- tabs -->
    <div class="tabs-box">
      <!-- 切换 -->
      <div class="tabs">
        <a
          class="tabsdiv"
          v-if="$route.query.productId"
          href="javascript:;"
          @click="tabIndex = 0"
          :class="tabIndex == 0 ? 'a1' : ''"
        >
          <span>课程介绍</span>
        </a>
        <a
          class="tabsdiv"
          v-if="$route.query.productId && tree.length"
          href="javascript:;"
          @click="tabIndex = 1"
          :class="tabIndex == 1 ? 'a2' : ''"
        >
          <span>目录</span>
        </a>
        <a
          class="tabsdiv"
          v-if=" $route.query.productId&&shopDetail.teacherList &&shopDetail.teacherList.length"
          href="javascript:;"
          @click="tabIndex = 2"
          :class="tabIndex == 2 ? 'a2' : ''"
        >
          <span>导师介绍</span>
        </a>
        <a
          class="tabsdiv"
          v-if="$route.query.productId && shopDetail.studentInstructions"
          href="javascript:;"
          @click="tabIndex = 3"
          :class="tabIndex == 3 ? 'a2' : ''"
        >
          <span>学习指导</span>
        </a>
        <a
          class="tabsdiv"
          v-if="$route.query.productId && shopDetail.registrationGuide"
          href="javascript:;"
          @click="tabIndex = 4"
          :class="tabIndex == 4 ? 'a2' : ''"
        >
          <span>报名流程</span>
        </a>
        <a
          class="tabsdiv"
          v-if="
            $route.query.productId &&
            shopDetail.relevantCertificateList &&
            shopDetail.relevantCertificateList.length
          "
          href="javascript:;"
          @click="tabIndex = 5"
          :class="tabIndex == 5 ? 'a2' : ''"
        >
          <span>相关证书</span>
        </a>
        <!-- <a
          v-if="shopDetail.couponPrice"
          class="tabsdiv"
          href="javascript:;"
          @click="tabIndex = 6"
          :class="tabIndex == 6 ? 'a2' : ''"
        >
          <span>评价</span>
        </a> -->
        <a
          v-if="materialList.length"
          class="tabsdiv"
          href="javascript:;"
          @click="tabIndex = 7"
          :class="tabIndex == 7 ? 'a2' : ''"
        >
          <span>讲义</span>
        </a>
      </div>
      <!-- 视图 -->
      <div class="contenttab">
        <!-- 课程介绍 -->
        <div v-if="tabIndex == 0" v-html="shopDetail.detail"></div>
        <!-- 目录 -->
        <template v-else-if="tabIndex == 1">
          <forTree :tree="tree" @nodeClick="treeClick" :isOrder="true" :courseId="courseId" :courseName="shopDetail.courseName" :isHave="shopDetail.have" />
        </template>
        <!-- 导师介绍 -->
        <template v-else-if="tabIndex == 2">
          <!-- {{shopDetail.teacher}} -->
          <div
            class="teacherItem"
            v-for="(item, index) in shopDetail.teacherList"
            :key="index"
          >
            <div class="flexBox">
              <div class="imgTitle">
                <div
                  class="pictureName"
                  style="background: #E5E5E5"
                >
                  <img :src="item.squarePicture" alt="" />
                </div>
                <p class="name" style="background: #E5E5E5">
                  {{ item.name }}
                </p>
                <div
                  class="border"
                  style="border-color:#E5E5E5"
                ></div>
                <div
                  class="triangle"
                  style="border-left-color:#E5E5E5 "
                ></div>
              </div>
              <!-- <div class="titleList">
                <p
                  class="titleItem"
                  v-for="(ite, ind) in item.title.split(',')"
                  :key="ind"
                >
                  <span :style="{ background: colorList[index] }"></span
                  >{{ ite }}
                </p>
              </div> -->
              <swiper
                ref="mySwiper"
                class="titleList"
                :options="teacherSwiperOptions"
              >
                <swiper-slide
                  v-for="(itemI, indexI) in item.title"
                  :key="indexI"
                >
                  <div
                    class="titleItem"
                    v-for="(itemII, indexII) in itemI"
                    :key="indexII"
                  >
                    <span style="background: #E5E5E5"></span
                    >{{ itemII }}
                  </div>
                </swiper-slide>
                <div
                  v-show="item.title.length > 1"
                  v-for="(itemI, indexI) in item.title"
                  :key="indexI + 1"
                  class="swiper-pagination"
                  slot="pagination"
                ></div>
              </swiper>
            </div>
            <div class="detail">
              {{regtxt(item.introduction)}}
            </div>
            <img
              src="@/assets/image/knowledgeMall/tag.png"
              alt=""
              class="fly"
            />
          </div>
        </template>
        <!-- 学员须知 -->
        <div
          v-else-if="tabIndex == 3"
          v-html="shopDetail.studentInstructions"
        ></div>
        <!-- 报名流程 -->
        <div
          v-else-if="tabIndex == 4"
          v-html="shopDetail.registrationGuide"
        ></div>
        <!-- 报名流程 -->
        <div v-else-if="tabIndex == 5">
          <div
            class="relevantCertificateList"
            v-for="(item, index) in shopDetail.relevantCertificateList"
            :key="index"
          >
            <img :src="item.picture" alt="" />
            <p>{{ item.name }}</p>
          </div>
        </div>
        <!-- 评价 -->
        <div v-else-if="tabIndex == 6">
          <CommentList :pruductId="this.$AES.decode_data(this.$route.query.productId)"/>
        </div>
        <!-- 讲义 -->
        <div v-else-if="tabIndex == 7">
          <div class="material" @click="lookmaterial(item)" v-for="(item,index) in materialList" :key="index">
            <div class="mess">
              <img class="materialIcon" src="@/assets/image/knowledgeMall/materialIcon.png" alt="">
              <p class="courseName">{{item.name}}</p>
              <img class="courseIcon" v-if="item.materialType == 1" src="@/assets/image/knowledgeMall/courseIcon.png" alt="">
              <p class="sectionName" v-else>/ {{item.sectionName}}</p>
            </div>
            <div class="lookBtn"><p>查看</p><a-icon class="icon" type="right" /></div>
          </div>
        </div>
      </div>
    </div>
    <Shopwarning :visible="visible" :fontText="fontText" />

    <!-- 请先完成身份认证弹窗 -->
    <TipsModal 
      ConfirmBtnText="去认证"
      :isPublicTipsShow="confirmPopup" 
      tipsText="购买该课程需要身份认证"
      @setEvent="goAuthentication" />
    <!-- 请先选择班期 -->
    <TipsModal 
      :isCancelBtnShow="false"
      ConfirmBtnText="我知道了"
      :isPublicTipsShow="isPublicTipsShow" 
      :tipsText="tipsText"
      @setEvent="isPublicTipsShow = false" />
    <!-- 提交资质弹框 -->
    <SubmitQualification
      :courseId="courseId"
      :tipsText="tipsText"
      :qualificationvisible="qualificationvisible"
      @onCancel="cancelQuali"
      @onConfirm="confirmQuali"/>
    <Sidebar ref="sidebar"/>
    <EquityModal :isEquityShow = "isEquityShow" @closeEvent="isEquityShow = false" />

    <!-- 购买须知弹框 -->
    <a-modal
      v-if="shopDetail.prebuyNotice && !shopDetail.have"
      title="《购买须知》"
      :visible="prebuyNoticeVisible"
      @cancel="prebuyNoticeVisible = false"
    >
      <template #footer>
        <a-button class="enterNote" @click="prebuyNoticeVisible = false,prebuyNoticeFlag = true">我已了解该须知</a-button>
      </template>
      <div v-richText="{htmlText: shopDetail.prebuyNotice, styleText: 'p {margin: 0;}'}"></div>
    </a-modal>
  </div>
</template>

<script>
import {userTrack} from '@/unit/userTrack.js';
import forTree from "@/components/forTree/index.vue";
import SubmitQualification from '@/components/model/submitQualification.vue' // 提交资质
import Sidebar from "@/components/sidebar.vue";
import CommentList from "@/components/commentList.vue";
import EquityModal from "./EquityModal.vue";
export default {
  // 可用组件的哈希表
  components: { 
    forTree,
    SubmitQualification, // 提交资质
    Sidebar,
    CommentList,
    EquityModal
  },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      productCode:0,
      tipsText: "", //
      isEquityShow:false,
      couponFlag: false, // 优惠券弹框状态
      qualificationvisible: false, // 提交资质弹框
      isPublicTipsShow: false,
      confirmPopup: false, // 身份认证弹窗标识
      clientWidth: 0, // 视口宽度
      imgSrc1: require("@/assets/image/temporary/Group4627.png"),
      openTimeId: "",
      courseId: "",
      classList: [], // 商品-班期list
      visible: false,
      fontText: "", // 文本
      tabIndex: 0,
      formData: {},
      tree: [],
      mallClass: false, // 更多期数状态
      imgSrc: require("@/assets/image/temporary/pic_nav.jpg"),
      tabMenu: ["全部", "213"],
      courseNum:1,
      couponList: null, // 优惠券列表
      // swiperOptions: {
      //   slidesPerView: 4,
      //   centeredSlides: true,
      //   centeredSlidesBounds: true,
      //   navigation: {
      //     nextEl: ".swiper-button-next",
      //     prevEl: ".swiper-button-prev",
      //     disabledClass: "my-button-disabled",
      //   },
      //   // Some Swiper option/callback...
      // },
      teacherSwiperOptions: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      // 包含课程列表
      courseList: [],
      // 图书教具列表
      productList: [],
      // 套餐列表
      setmealList: [],
      shopDetail: null,
      colorList: [],
      seriesproductList: [], // 赠品
      // 图书教具轮播配置
      productSwiperOptions: {
        slidesPerView: "1.6",
        centeredSlides: false,
        // spaceBetween: 30,
        centeredSlidesBounds: true,
        navigation: {
          nextEl: ".swiper-product-next",
          prevEl: ".swiper-product-prev",
          disabledClass: "my-button-disabled",
        },
        // Some Swiper option/callback...
      },
      swiperOptions: {
        slidesPerView: "auto",
        centeredSlides: false,
        // spaceBetween: 30,
        centeredSlidesBounds: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          disabledClass: "my-button-disabled",
        },
        // Some Swiper option/callback...
      },
      materialList: [], // 讲义列表

      prebuyNoticeFlag: false, // 购买须知是否同意
      prebuyNoticeVisible: false, // 购买须知弹框控制器
    };
  },

  directives: {
    // shadow DOM 处理富文本样式避免被外界样式污染
    richText: {
      inserted: function(el, binding) {
        const shadowRoot = el.attachShadow({ mode: 'closed' })
        let htmlText, styleText
        if (typeof binding.value === 'object') {
          htmlText = binding.value.htmlText
          styleText = binding.value.styleText
        } else {
          htmlText = binding.value
        }
        shadowRoot.innerHTML = htmlText
        if (styleText) {
          // 给富文本添加样式
          const style = document.createElement('style')
          style.textContent = styleText
          shadowRoot.appendChild(style)
        }
      }
    }
  },
  // 事件处理器
  methods: {
    closemodel(){
      if(this.couponFlag) {
        this.couponFlag = false;
      }
    },
    useTime(ite) {
      if(ite.times == 2) {
        if(ite.startTime && ite.lapseTime) {
          return ite.startTime.split(' ')[0] + '-' + ite.lapseTime.split(' ')[0]
        }
      }else if(ite.times == 3) {
        if(ite.lapseTime) {
          return '至'+ ite.lapseTime.split(' ')[0]
        }
        if(ite.days) {
          return '领取后'+ite.days+'天内可用'
        }
      }else if(ite.times == 1) {
        return '不限'
      }
    },
    // 去学习
    goStudy(e) {
      if (e.validStatus == 2) {
        this.$message.warning("请前往学习中心-在学课程中申请延期");
        return;
      }
      if (!e.hasVideoFlag && !e.hasSectionFlag) {// 没有章节、没有视频，提示文字
        return this.$message.warning("课程暂未更新，请耐心等待开课")
      }
      if (e.isOpenClass == 1) {
        let arr = e.startTime.split("-");
        this.$message.warning(
          "课程学习尚未开始，开始时间为" +
            arr[0] +
            "年" +
            arr[1] +
            "月" +
            arr[2] +
            "日"
        );
      } else {
        // 课程是否顺序播放  watchByCatalogueOrder 1.按顺序播放  0.不按顺序播放
        if(this.shopDetail.watchByCatalogueOrder == 1){
          this.$ajax({
            url: "/hxclass-pc/course/go-study",
            params: {
              courseId: e.courseId,
            },
          }).then((res) => {
            if (res.code == "200" && res.success) {
              this.$router.push(
                "/curriculum/course?courseId=" +
                this.$AES.encode_data(String(e.courseId)) +
                "&isBuy=" +
                "&isHave=1"
              );
            }else{
              if(res.code == -2){
                let str = res.message + "若已通过其他平台获得资质，请前往app提交资质进行学习。";
                res.message = str;
              }
              this.$message.warning(res.message);
            }
          });
        } else {// 跳转不需要按顺序播放的课程详情页
          this.$ajax({
            url: "/hxclass-pc/course/getUserCurrentSection",
            params: {
              courseId: e.courseId,
            },
          }).then((res) => {
            if (res.code == "200" && res.success) {
              sessionStorage.removeItem("NextVod");

              this.$router.push(
                "/curriculum/courseRandom?courseId=" +
                this.$AES.encode_data(String(e.courseId)) +
                "&isHave=1"
              );
            }else{
              this.$message.warning(res.message);
            }
          });
        }
      }
    },
    // 跳转商品详情
    goShopDetail(item,flag){
      // if(flag){
      //   if(!this.shopDetail.have){
      //     return ;
      //   }
      // }
      // console.log(item)
      if (item.productType == 1 || item.type == 1) {
        this.$router.push(
          "/knowledgeMall/courseDetail?productId=" +
            this.$AES.encode_data(item.productId + "") +
            "&code=" +
            item.code
        );
      } else if (
        item.productType == 2 ||
        item.productType == 3 ||
        item.type == 2 ||
        item.type == 3
      ) {
        this.$router.push(
          "/knowledgeMall/curriculumDetail?productId=" +
            this.$AES.encode_data(item.productId + "")
        );
      } else if (item.productType == 4 || item.type == 4) {
        this.$router.push(
          "/knowledgeMall/examDetail?productId=" +
            this.$AES.encode_data(item.productId + "")
        );
      }
    },

    // 判断课程是否可购买
    async checkCanBuy(){
      let res = await this.$ajax({
				url:'/hxclass-pc/course/check-can-buy',
				method:'get',
				params:{
					courseId:this.courseId
				}
			})
      if(res.code == 1000){ // 需购买课程
        this.tipsText = '购买该课程需要先购买<span style="color:#15B7DD;white-space:normal;word-break:break-all;word-wrap:break-word;">'+res.data.courseNameList.join('、')+'</span>。';
        this.isPublicTipsShow = true;
      }else if(res.code == 1001){ // 需提交资质
        this.tipsText = '';
        this.qualificationvisible = true;
      }else if(res.code == 1002){ // 已提交资质待审核
        this.tipsText = '您已成功提交申请，1-3个工作日内将完成审核，审核结果将以短信的方式通知，请留意。';
        this.isPublicTipsShow = true;
      }else if(res.code == 1003){ // 审核被驳回
        this.tipsText = '';
        this.qualificationvisible = true;
      }else if(res.code == 1004){ // 需购买课程且提交资质
        this.tipsText = '购买该课程需要先购买<span style="color:#15B7DD;white-space:normal;word-break:break-all;word-wrap:break-word;">'+res.data.courseNameList.join('、')+'</span>，如您已获得上述资质，请提交您的资质信息，后台审核通过后方可报名。';
        this.qualificationvisible = true;
      }else if(res.code == 1005){ // 课程无可用班期
        this.$message.error(res.message);
      }else if (res.code == 1006) { // 该课程已购买
        this.$message.error(res.message);
      }
      return res;
    },
    async joinShopCar(){
      if(!this.$store.state.userInfo.userId){
				return this.$router.push('/login/loginIndex?type=2');
			}
			if(this.shopDetail.setOpenClass && !this.classList?.length){
				return this.$message.error('暂无可选择班级期数无法进行购买');
			}
      if(!!this.shopDetail.userAuthentication && !this.$store.state.userInfo.userType){
				// return this.$message.error('购买该课程需要身份认证');
        return this.confirmPopup = true;
			}
      let _this = this;
      let res = await this.checkCanBuy()
      if(res.code == 200 && res.success){
        if(this.classList?.length && !this.openTimeId){
          this.tipsText = '请先选择课程期数，再进行购买。';
          return this.isPublicTipsShow = true;
        }
        if(this.shopDetail.prebuyNotice && !this.prebuyNoticeFlag) {
          return this.$message.warning('请阅读购买须知');
        }
        this.$ajax({
          url:'/hxclass-pc/pc-mall/shop/save',
          method:'post',
          params:{
            count: this.courseNum, // 数量
            couponCode:'', // 优惠劵编码
            openTimeId:this.openTimeId, // 开班设置id
            price:this.shopDetail.couponPrice, // 价格  优惠价格-还没有计算优惠券
            productId:this.shopDetail.productId, // 商品id
            type:1, // 商品类型1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考
            userNo:this.$store.state.userInfo.userId, // 用户
          }
        }).then(res=>{
          if(res.code == 200 && res.success){
            this.$message.success('添加购物车成功');
            this.mollJoinCar();
            return true;
          }else{
            this.$message.error(res.message)
            return false;
          }
        })


        // this.$ajax({
        //   url:'/hxclass-pc/pc-mall/app/shop/use',
        //   method:"get",
        //   params:{
        //     openTimeId:this.openTimeId,
        //     productId:this.shopDetail.productId,
        //     type:1, // 商品类型1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播	
        //   }
        // }).then(res=>{
        //   if(res.code == 200){
        //     this.$message.error('该课程已加购，不可重复购买')
        //     return false;
        //   }else{
        //     this.$ajax({
        //       url:'/hxclass-pc/pc-mall/shop/save',
        //       method:'post',
        //       params:{
        //         count:1, // 数量
        //         couponCode:'', // 优惠劵编码
        //         openTimeId:this.openTimeId, // 开班设置id
        //         price:this.shopDetail.couponPrice, // 价格  优惠价格-还没有计算优惠券
        //         productId:this.shopDetail.productId, // 商品id
        //         type:1, // 商品类型1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考
        //         userNo:this.$store.state.userInfo.userId, // 用户
        //       }
        //     }).then(res=>{
        //       if(res.code == 200 && res.success){
        //         this.$message.success('添加购物车成功');
        //         this.mollJoinCar();
        //         return true;
        //       }else{
        //         this.$message.error(res.message)
        //         return false;
        //       }
        //     })
        //   }
        // })
      }
    },

    // 改变商品数量
    changeCourseNum(i) {
      if (i < 0) {
        if (this.courseNum != 1) {
          this.$set(this, "courseNum", this.courseNum + i);
        }
      } else {
        this.$set(this, "courseNum", this.courseNum + i);
      }
    },

    // 批量加入购物车判断
		mollJoinCar(){
			let record = [];
			if(this.productList && this.productList.length){
				this.productList.map(item=>{
					if(item.checked){
						record.push({
							count:item.count, // 数量
							couponCode:'', // 优惠劵编码
							openTimeId:'', // 开班设置id
							price:item.couponPrice, // 价格  优惠价格-还没有计算优惠券
							productId:item.productId, // 商品id
							type:item.productType, // 商品类型1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考
							userNo:this.$store.state.userInfo.userId, // 用户
						})
					}
				})
			}
			if(record.length){
				// 相关商品批量加入购物车
				this.joinCarAll(record);
			}else{
        this.$refs['sidebar'].getShopList()
      }
		},
		// 批量加入购物车
		joinCarAll(e){
			this.$ajax({
				url:'/hxclass-pc/pc-mall/app/batch/save',
				method:'post',
				params:e
			}).then(res=>{
				if(res.code == 200 && res.success){
          this.$refs['sidebar'].getShopList()
				}else{
					this.$message.error(res.message)
				}
			})
		},
    async buyNow(){
      if(this.shopDetail.have && !this.shopDetail.buyLimitNum){
        this.goStudy(this.shopDetail);
        return false;
      }
      if(!this.$store.state.userInfo.userId){
				return this.$router.push('/login/loginIndex?type=2');
			}
			if(this.shopDetail.setOpenClass && !this.classList?.length){
				return this.$message.error('暂无可选择班级期数无法进行购买');
			}
			if(!!this.shopDetail.userAuthentication && !this.$store.state.userInfo.userType){
				// return this.$message.error('购买该课程需要身份认证');
        return this.confirmPopup = true;
			}
      let res = await this.checkCanBuy()
      if(res.code == 200 && res.success){
        if(this.classList?.length && !this.openTimeId){
          this.tipsText = '请先选择课程期数，再进行购买。';
          return this.isPublicTipsShow = true
        }
        if(this.shopDetail.prebuyNotice && !this.prebuyNoticeFlag) {
          return this.$message.warning('请阅读购买须知');
        }
        let list = [];
        let productList = [];
        this.seriesproductList.map(item=>{
          productList.push({
            productId:item.productId, // 商品id
            count:this.courseNum, // 数量")
            openTimeId:'', // 班级id
            type:item.type, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
            province:'', // 省
            city:'',// 市
            noGift:1, // 是否为赠品 0否1是t
          })
        })
        list.push({
          productId:this.shopDetail.productId, // 商品id
          count:this.courseNum, // 数量")
          openTimeId:this.openTimeId, // 班级id
          type:1, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
          province:'', // 省
          city:'',// 市
          noGift:0, // 是否为赠品 0否1是t
          productList:productList
        })
        // 相关商品
        this.productList.map(item=>{
          if(item.checked){
            list.push({
              productId:item.productId, // 商品id
              count:item.count, // 数量")
              openTimeId:'', // 班级id
              type:item.productType, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
              province:'', // 省
              city:'',// 市
              noGift:0, // 是否为赠品 0否1是t
            })
          }
        })
        this.$store.commit('updatelist',list);
        this.$store.commit('updateType',1);
        this.$store.commit('updateInvoiceId',null);
        this.$store.commit('updateShortInvoiceId',null);
        this.$store.commit('updateAddress',null);
        this.$store.commit('updateCouponList',null);
        this.$router.push('/order/confirmOrder')
      }
    },
    // 加入学习
    async joinStudy() {
      if(this.shopDetail.have){
        this.goStudy(this.shopDetail);
        return false;
      }
      if(!this.$store.state.userInfo.userId){
				return this.$router.push('/login/loginIndex?type=2');
			}
			if(this.shopDetail.setOpenClass && !this.classList?.length){
				return this.$message.error('暂无可选择班级期数无法进行购买');
			}
			if(!!this.shopDetail.userAuthentication && !this.$store.state.userInfo.userType){
				// return this.$message.error('购买该课程需要身份认证');
        return this.confirmPopup = true;
			}
      let res = await this.checkCanBuy()
      if(res.code == 200 && res.success){
        if(this.classList?.length && !this.openTimeId){
          this.tipsText = '请先选择课程期数，再进行购买。';
          return this.isPublicTipsShow = true
        }
        this.$ajax({
          url:'/hxclass-pc/course/join-study',
          method:'post',
          params:{
            courseId:this.shopDetail.courseId, //	课程id
            openTimeId:this.openTimeId, //	开课时间id
            userId:this.$store.state.userInfo.userId, //	用户id
          }
        }).then(res=>{
          if(res.code == 200 && res.success){
            this.$message.success('已成功加入');
            this.shopDetail.have = 1
            this.shopDetail.couponPrice = 0
            this.getTree();
          }else{
            this.$message.error(res.message);
          }
        })
      }
    },
    // 取消提交资质
    cancelQuali(){
      this.qualificationvisible = false;
    },
    // 提交资质
    confirmQuali(e){
      console.log(e)
      if(!e.imageUrl){
        return this.$message.error('请上传相关资质')
      }else if(!e.type){
        return this.$message.error('请选择资质类型')
      }
      // else if(!e.type){
      //   return this.$message.error('请选择资质类型')
      // }
      this.$ajax({
        url:'/hxclass-pc/course/course-buy-audit/commit',
        method:'post',
        params:{
          certifTempId: e.type, // 资质模板id
          code:e.code, //
          content: e.textarea, // 内容
          courseId: this.courseId, // 课程id
          relevantFile: e.fileSrc, // 相关文件
          relevantFileName: e.fileName, // 相关文件名称
          relevantProof: e.imageUrl, // 相关证明
          userId: this.$store.state.userInfo.userId, // 用户id
        }
      }).then(res=>{
        if(res.code == 200){
          this.visible = true;
          this.fontText = res.message;
          this.qualificationvisible = false;
        }else{
          return this.$message.error(res.message);
        }
      })
    },
    // 清除富文本标签
    regtxt(e){
      let a= e.replace(/<[^>]+>/g,'')
      return a
    },

    selectOpenTimeId(e) {
      if (!e.currentNumber) {
        return this.$message.error("该班级已报满，请选择其他班级");
      }
      this.openTimeId = e.openTimeId;
    },
    randomColor() {
      var color = "#";
      for (var i = 0; i < 6; i++)
        color += parseInt(Math.random() * 16).toString(16);
      return color;
    },
    // 改变商品数量
    changeCount(e, i) {
      if(e.productType == 1) return this.$message.warning('课程类型商品只能买一个！')
      if (i < 0) {
        if (e.count != 1) {
          e.count += i;
        }
      } else {
        e.count += i;
      }
    },
    // 价格处理
    managePrice(con) {
      let txt = (con * 1).toFixed(2).split(".");
      return txt;
    },
    // 教师列表处理
    manageTeacher() {
      if (!this.shopDetail.teacherList || this.shopDetail.teacherList.length == 0) {
        return;
      }
      let teacherlabel = [[]]; //教师标签
      let teacherSwiper = 0; //教师数组个数
      let count = 0; //计数一组放四个
      // 教师
      this.shopDetail.teacherList.map((item, index) => {
        // 标签
        item.title.split(",").map((ite, index) => {
          teacherlabel[teacherSwiper].push(ite);
          count++;
          // 超过4个,原判定是等于4就++
          // if (count == 4) {
          if (count == 4) {
            teacherlabel.push([]) //添加新分组
            teacherSwiper++ //分组+1
            count = 0 //计数清零
          }
        });
        if (item.title.split(",").length%4==0) {
           teacherSwiper--;
           teacherlabel.pop();
        }
        // 赋值
        this.$set(this.shopDetail.teacherList[index], 'title', teacherlabel)
        teacherlabel = [[]]
        teacherSwiper = 0
        count = 0
      })
    },

    // 查询目录信息
    getTree() {
      let url = ''
      if(this.shopDetail.have == 1){
        url = this.shopDetail.watchByCatalogueOrder ? "/hxclass-pc/course/course-section/tree" : "/hxclass-pc/course/course-section/random/tree"
      } else {
        url = '/hxclass-pc/course/course-section/tree'
      }
      this.$ajax({
        url: url,
        params: {
          courseId: this.courseId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          // console.log(res.data);
          res.data.forEach(element => {
            element.isMenuOpen = true
          });
          this.tree = res.data;
        }
      });
    },

    initVideo({ fileID, psign, disabledRate }) {
      this.player = TCPlayer("player-container-id", {
        // player-container-id 为播放器容器 ID，必须与 html 中一致
        fileID: fileID, // 播放的视频 fileID（必须）
        appID: "1500012233", // 点播账号的appID（必须）
        psign: psign, // 签名
        autoplay: true, // 是否自动播放
        bigPlayButton: false, // 是否显示居中的播放按钮
        controlBar: {
          progressControl: !disabledRate, // 是否显示进度条
          playbackRateMenuButton: !disabledRate, // 是否显示播放速率选择按钮
        },
        plugins: {
          ContinuePlay: {
            btnText: "点击继续观看",
          },
        },
      });
      // 播放开始执行
      this.player.on("play", () => {
        this.paused = false;
        this.ended = false;
      });
      // 播放暂停执行
      this.player.on("pause", () => {
        this.paused = true;
      });
      // 播放结束执行
      this.player.on("ended", () => {
        this.ended = true;
      });
    },

    // 查询视频播放信息
    getVod() {
      if(!this.$store.state.userInfo.userId){
        return this.$router.push('/login/loginIndex?type=2')
      }
      sessionStorage.setItem("selfTess",this.sectionId);
      this.$router.push('/curriculum/course?courseId='+this.$AES.encode_data(this.courseId + "") + '&productId='+this.$route.query.productId + '&isHave=' + this.shopDetail.have)
    },

    // 目录点击
    treeClick(sectionId) {
      if(this.shopDetail.have == 1){
        if (this.shopDetail.validStatus == 2) {
          this.$message.warning("请前往学习中心-在学课程中申请延期");
          return;
        }
        if (this.shopDetail.isOpenClass == 1) {
          let arr = this.shopDetail.startTime.split("-");
          this.$message.warning(
            "课程学习尚未开始，开始时间为" +
              arr[0] +
              "年" +
              arr[1] +
              "月" +
              arr[2] +
              "日"
          );
        } else {
          if(this.shopDetail.watchByCatalogueOrder == 1){
            this.$ajax({
              url: "/hxclass-pc/course/go-study",
              params: {
                courseId: this.shopDetail.courseId,
              },
            }).then((res) => {
              if (res.code == "200" && res.success) {
                this.sectionId = sectionId;
                this.getVod();
              }else{
                if(res.code == -2){
                  let str = res.message + "若已通过其他平台获得资质，请前往app提交资质进行学习。";
                  res.message = str;
                }
                this.$message.warning(res.message);
              }
            });
          } else {
            this.$ajax({
              url: "/hxclass-pc/course/getClickSectionVod",
              params: {
                courseId: this.shopDetail.courseId,
                sectionId: sectionId,
              },
            })
              .then((res) => {
                if (res.code == 200 && res.success) {
                  sessionStorage.setItem('NextVod', JSON.stringify(res.data))
                  this.$router.push(
                    "/curriculum/courseRandom?courseId=" +
                    this.$AES.encode_data(String(this.shopDetail.courseId)) +
                    "&isHave=1"
                  );
                } else {
                  this.$message.warning(res.message);
                }
              })
              .catch((err) => {
                // console.log(err);
              });
          }
        }
      } else {
        this.sectionId = sectionId;
        this.getVod();
      }
    },

    // 获取课程关联图书教具列表
    getRelatedGoods(e) {
      this.$ajax({
        url: "/hxclass-pc/course/course-relevant-product/by-course-product-code",
        method: "get",
        params: {
          courseProductCode: e,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.productList = res.data.map((item) => {
            item["count"] = 1;
            return item;
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 获取课程详情
    getCourseDetail() {
      // 记录用户轨迹
      let params = {
        actionDetail: this.$AES.decode_data(this.$route.query.productId + ""), //	访问资源id（如对应的banner、商品和文章id，路径触发先设置首页为1）
        actionType: 3, //	触发分类：1、banner触发(轮播图、弹窗)、2 路径触发(启动访问首页)、3商品详情、4 文章详情
        os: 3, //	1、ios2、安卓 3、PC 4、H5
        userId: this.$store.state.userInfo.userId
      }
      this.saveUserTrack(params);

      this.$ajax({
        // url: "/hxclass-pc/course/by-product-id",
        url: "/hxclass-pc/course/" + this.$AES.decode_data(this.$route.query.productId + ""),
        method: "get",
        // params: {
        //   productId: this.$AES.decode_data(this.$route.query.productId + ""),
        // },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          if (!res.data) {
            return this.$message.error("该课程已下架");
          }
          this.courseId = res.data.courseId;
          this.shopDetail = res.data;

          this.getmaterialList();
          this.getTree();
          if(this.$route.query.code){
            this.productCode = this.$route.query.code
          } else {
            this.productCode = res.data.productCode
          }

          // 处理教师列表数据
          this.manageTeacher();
          this.getshopCourseDateList(this.productCode); // 获取课程期数
          this.getRelatedGoods(this.productCode); // 获取关联图书教具
          if (res.data.teacherList && res.data.teacherList.length) {
            res.data.teacherList.map((item) => {
              this.colorList.push(this.randomColor());
            });
            // console.log(this.colorList);
          }
          this.imgSrc = res.data.picture;
        } else {
          this.$message.error(res.message);
        }
      });
    },

    selectTime(){
      //时间戳
      // let time = +new Date();
      //时间戳
      let time = new Date().getTime();
      var date = new Date(time + 8*3600*1000); // 增加8小时
      return date.toJSON().substr(0, 19).replace('T', ' ');
    },

    // 领取优惠券
    receiveCoupon(e) {
      if(!this.$store.state.userInfo.userId){
        return this.$router.push('/login/loginIndex?type=2')
      }
      if(this.receiveCouponflag){
        return 
      }
      this.receiveCouponflag = true;
      this.$ajax({
        url:'/hxclass-pc/pc-mall/app/save-draw',
        method:'post',
        params:{
          couponId:e.couponId
        }
      }).then(res=>{
        this.receiveCouponflag = false;
        if(res.code == 200 && res.success){
          this.$message.success('领取成功');
          this.getCouponList();
        }else{
          this.$message.error(res.message);
        }
      })

      // 旧版
      // this.$ajax({
      //   url:'/hxclass-pc/pc-mall/shop/coupon-receive',
      //   method:'post',
      //   params:{
      //     couponId:e.couponId,
      //     receiver:this.$store.state.userInfo.userId,
      //     receiverTime:this.selectTime(),
      //     state:1
      //   }
      // }).then(res=>{
      //   this.receiveCouponflag = false;
      //   if(res.code == 200 && res.success){
      //     this.$message.success('领取成功');
      //     this.getCouponList();
      //   }else{
      //     this.$message.error(res.message);
      //   }
      // })
    },

    // 获取优惠券列表
    getCouponList(e) {
      this.$ajax({
        url: "/hxclass-pc/pc-mall/product/coupon",
        method: "get",
        params: {
          productId: this.$AES.decode_data(this.$route.query.productId + ""), //	购物车商品 1,2,3,4
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.couponList = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
      
      // 旧版优惠券列表
      // this.$ajax({
      //   url: "/hxclass-pc/pc-mall/shop/coupon-list",
      //   method: "get",
      //   params: {
      //     productId: this.$AES.decode_data(this.$route.query.productId + ""), //	购物车商品 1,2,3,4
      //     type: e, // 1商品 2套餐
      //     productType: 1,
      //   },
      // }).then((res) => {
      //   if (res.code == 200 && res.success) {
      //     this.couponList = res.data;
      //   } else {
      //     this.$message.error(res.message);
      //   }
      // });
    },
    // 商品详情-查询课程期数
    getshopCourseDateList(e) {
      this.$ajax({
        url: "/hxclass-pc/course/pc/by-code",
        method: "get",
        params: {
          productCode: e,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.classList = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 获取赠品相关商品列表
    getShopList() {
      this.$ajax({
        url: "/hxclass-pc/pc-mall/pc/series/product",
        method: "get",
        params: {
          productId: this.$AES.decode_data(this.$route.query.productId + ""),
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.seriesproductList = res.data.filter((item) => {
            // if(item.nexus == 1){
            //   return item.type == 2 || item.type == 3;
            // }
            return item.nexus == 2;
          });
          // console.log(this.seriesproductList);
        }
      });
    },
    // 获取讲义列表
    getmaterialList() {
      this.$ajax({
        url: "/hxclass-pc/course/select/byCourseId",
        params: {
          courseId: this.courseId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.materialList = res.data;
        }else {
          this.$message.error(res.message)
        }
      });
    },
    lookmaterial(e) {
      if(!e.url){
        return
      }
      if(!this.$store.state.userInfo.userId){
				return this.$router.push('/login/loginIndex?type=2');
			}
      if(!this.shopDetail.have){
        return this.$message.warn('请先购买该课程！');
      }

      // 点击量
      this.$ajax({
        url: "/hxclass-pc/course/select/updateViewNum?id=" + e.id,
        method: "put",
      }).then((res) => {});
      
      if(e.downloadFlag == 1){
        window.open(e.url);
      } else{
        window.open('/knowledgeMall/PdfView?link=' + this.$AES.encode_data(String(e.url)));
      }
    },

    // 记录用户轨迹
    saveUserTrack(params) {
      userTrack(params)
    },
    // 去认证
    goAuthentication(){
      this.$router.push("/personalCenter/identityAuthentication")
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.clientWidth = document.body.clientWidth;
    // this.getShopDetail();
    if (this.$route.query.productId) {
      this.getCourseDetail();
      this.getShopList();
      // 获取优惠券列表
      this.getCouponList(1);
    }
  },
  // 生命周期-实例挂载后调用
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        // console.log(document.body.clientWidth);
        that.clientWidth = window.screenWidth;
      })()
    }
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  // 自定义的侦听器
  watch: {
    "document.body.clientWidth"(newVal,oldVal){
      // console.log(newVal)
    },
    "$route.query.productId"(newVal,oldVal){
      this.getCourseDetail();
      this.getShopList();
      // 获取优惠券列表
      this.getCouponList(1);
    }
  },
};
</script>

<style lang="less" scoped>
/deep/.ant-modal-close-x{
  width: 89px;
  height: 89px;
  line-height: 89px;
}
/deep/.ant-modal-header{
  border-bottom: 0;
  padding-left: 30px;
  padding-top: 30px;
  .ant-modal-title{
    font-weight: 500;
    font-size: 20px;
    font-family: PingFang SC, PingFang SC;
    color: #333333;
    line-height: 23px;
  }
}
/deep/.ant-modal-body{
  padding: 0 30px;
}
/deep/.ant-modal-footer{
  text-align: center;
  padding-bottom: 25px;
  padding-top: 7px;
  border-top: 0;
  .enterNote{
    height: 40px;
    background: #15B7DD;
    box-shadow: 0px 4 4px 0px rgba(49,188,221,0.25);
    border-radius: 20px 20px 20px 20px;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
  }
}
.teacherItem {
  padding-top: 34px;
  // width: 1170px; //没必要写宽度
  margin: 0 auto;
  position: relative;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 27px;
  padding-left: 26px;
  margin-bottom: 20px;
  /deep/.swiper-wrapper {
    padding-bottom: 25px;
  }
  /deep/.swiper-pagination {
    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      background: #e5e5e5;
      opacity: 1;
      margin: 0 8px;
    }
    .swiper-pagination-bullet-active {
      background-color: #15b7dd;
    }
  }
  .fly {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 27px;
    height: 27px;
  }
  .detail {
    margin-left: -26px;
    padding-top: 35px;
    font-size: 18px;
    font-family: PingFangMedium;
    font-weight: 400;
    // color: #333333;
    line-height: 36px;
  }
  .flexBox {
    display: flex;
    .imgTitle {
      position: relative;
      margin-right: 20px;
      height: 212px;
      .pictureName {
        width: 152px;
        height: 171px;
        background: red;
        border-radius: 16px 16px 0 0;
        overflow: hidden;
        img {
          width: 152px;
          height: 191px;
          margin-top: -20px;
          position: absolute;
          z-index: 1;
          border-radius: 16px 16px 0 0;
        }
      }
      .name {
        position: relative;
        z-index: 10;
        margin-top: 5px;
        width: 152px;
        line-height: 24px;
        font-size: 16px;
        font-family: PingFangMedium;
        color: #333333;
        background: red;
        border-radius: 4px;
        text-align: center;
        padding: 6px 10px;
      }
      .border {
        width: 182px;
        height: 202px;
        border: 1px solid red;
        position: absolute;
        top: 20px;
        left: -15px;
      }
      .triangle {
        width: 0;
        height: 0;
        border-top: 12px solid transparent;
        border-left: 24px solid red;
        border-bottom: 12px solid transparent;
        position: absolute;
        bottom: -35px;
        left: -25px;
        opacity: 0.5;
      }
    }
    .titleList {
      padding-left: 34px;
      padding-top: 28px;
      .titleItem {
        margin-bottom: 19px;
        font-size: 20px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #333333;
        line-height: 30px;
        span {
          display: inline-block;
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background: red;
          margin-right: 8px;
        }
      }
      .titleItem:last-child {
        margin: 0;
      }
    }
  }
}
.teacherItem:last-child {
  margin-bottom: 0;
}
.relevantCertificateList {
  width: 604px;
  margin: 0 auto;
  img {
    width: 604px;
  }
  p {
    text-align: center;
    font-size: 18px;
    font-family: PingFangMedium;
    color: #000000;
    line-height: 27px;
    margin-top: 12px;
    margin-bottom: 40px;
  }
}
@media screen and (max-width:16000px) {
  .custom-slick-arrow {
    cursor: pointer;
    margin: auto 0;
    width: 24px;
    height: 24px;
    font-size: 12px;
    color: #999999;
    background: #f5f5f5;
    border-radius: 12px;
    display: flex ;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .custom-slick-arrow:before {
    display: none;
  }
  .custom-slick-arrow:hover{
    color: #ffffff;
    background: #15B7DD;
  }
}
// 图书教具走马灯
.carouselList {
  display: flex !important;
  justify-content: left;
  // padding-left: 38px;
  align-items: center;
  height: 108px;
  @media screen and (max-width:16000px) {
    width: 510px !important;
    .custom-slick-arrow {
      width: 34px;
      height: 108px;
      font-size: 25px;
      color: #999999;
      background: #f5f5f5;
      border-radius: 5px;
      display: flex !important;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    .custom-slick-arrow:before {
      display: none;
    }
    /deep/.swiper-slide:last-child{
      width: 310px !important;
    }
  }
  .item {
    width: 310px;
    // height: 108px;
    background: #ffffff;
    border-radius: 5px;
    opacity: 1;
    border-right: 1px solid #ebebeb;
    margin-right: 10px;
    padding: 0 24px;
    display: flex;
    position: relative;
    .img {
      width: 60px;
      height: 60px;
      cursor: pointer;
    }
    .checked {
      width: 16px;
      height: 16px;
      position: absolute;
      left: 6px;
      top: 26px;
      cursor: pointer;
    }
    .right {
      cursor: pointer;
      margin-left: 16px;
      flex: 1;
      .produceName {
        width: 182px;
        font-size: 14px;
        font-family: PingFangMedium;
        color: #333333;
        line-height: 21px;
      }
      .bottom {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .price {
          font-size: 14px;
          font-family: PingFangMedium;
          color: #EC6C01;
          line-height: 21px;
        }
        .count {
          width: 80px;
          height: 24px;
          display: flex;
          align-items: center;
          padding: 0 6px;
          border-radius: 2px;
          border: 1px solid #ebebeb;
          .icon {
            font-size: 14px;
            color: #666666;
          }
          ::v-deep.ant-input-number-handler-wrap {
            display: none;
          }
          ::v-deep.ant-input-number {
            height: 20px;
            width: 77px;
            border: 0;
          }
          ::v-deep.ant-input-number-input {
            height: 20px;
            text-align: center;
          }
          ::v-deep.ant-input-number:focus {
            box-shadow: none !important;
          }
        }
      }
    }
  }
  .item:first-child {
    margin-left: 38px !important;
  }
  .noprev:first-child{
    margin-left: 0 !important;
  }
  @media screen and (max-width:16000px) {
    .item:first-child {
      margin-left: 0 !important;
    }
  }
  .item:last-child {
    margin: 0;
  }
}
.carouselListCenter {
  .item:first-child {
    margin-left: 0 !important;
  }
}
.ant-carousel {
  width: 707px;
  height: 108px;
  .slick-slide {
    text-align: center;
    height: 160px;
    line-height: 160px;
    background: #364d79;
    overflow: hidden;
  }
  .custom-slick-arrow {
    width: 34px;
    height: 108px;
    font-size: 25px;
    color: #999999;
    background: #f5f5f5;
    border-radius: 5px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .custom-slick-arrow:before {
    display: none;
  }
  // .custom-slick-arrow:hover {
  //   opacity: 0.5;
  // }
  .slick-slide h3 {
    color: #fff;
  }
}
// @media screen and (max-width:16000px) {
//   .ant-carousel{
//     width: 100px;
//   }
// }
.setmealList {
  background-color: #ffffff;
  padding: 40px 0;
  .steps {
    display: block;
    margin: 10px auto;
    width: 820px;
    height: 10px;
  }
  .stepsfour {
    display: block;
    margin: 10px auto;
    width: 1025px;
    height: 10px;
  }
  .shopMessage {
    @media screen and (max-width:16000px) {
      width: 664px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    .title {
      font-size: 25px;
      font-family: PingFangMedium;
      color: #333333;
      line-height: 25px;
      letter-spacing: 2px;
      margin-right: 25px;
    }
    .price {
      height: 58px;
      text-align: center;
      padding-top: 10px;
      .new {
        font-size: 18px;
        font-family: PingFangMedium;
        color: #EC6C01;
        line-height: 18px;
        margin-top: -5px;
        span {
          font-size: 31px;
        }
      }
      .old {
        margin-top: 2px;
        font-size: 16px;
        font-family: PingFangMedium;
        color: #666666;
        line-height: 19px;
      }
    }
    .warning {
      font-size: 16px;
      font-family: PingFangMedium;
      color: #999999;
      line-height: 19px;
      margin-left: 29px;
    }
    .button {
      cursor: pointer;
      background: #15b7dd;
      box-shadow: 0px 5px 5px 1px rgba(206, 206, 206, 0.25);
      border-radius: 26px 26px 26px 26px;
      padding: 4px 36px;
      margin-left: 21px;
      font-size: 23px;
      font-family: PingFangMedium;
      color: #ffffff;
      line-height: 34px;
      .icon {
        color: #ffffff;
        font-size: 23px;
        margin-right: 6px;
      }
    }
  }
}
.setmealshopList {
  display: flex;
  justify-content: center;
  .industryitem {
    padding: 0 29px;
  }
}
.industryitem {
  position: relative;
  z-index: 3;
  .itemcontent {
    text-align: center;
    img {
      width: 257px;
      height: 224px;
      border-radius: 5px 5px 0px 0px;
    }
    .setmealcontent {
      width: 257px;
      height: 114px;
      margin: 0 auto;
      text-align: left;
      padding: 12px 10px 21px;
      transition: all 0.5s;
      margin-bottom: 10px;
      box-shadow: 0px 5px 5px 1px rgba(93, 131, 167, 0.1);
      .name {
        font-size: 18px;
        font-family: PingFangMedium;
        color: #000000;
        line-height: 28px;
      }
      .nav {
        display: flex;
        align-items: center;
        margin-top: 7px;
        .label {
          font-size: 14px;
          font-family: PingFangMedium;
          color: #1e97b4;
          line-height: 21px;
        }
        .oldPrice {
          font-size: 14px;
          font-family: PingFangMedium;
          text-decoration: line-through;
          color: #999999;
          line-height: 21px;
          margin-right: 9px;
        }
        .navs {
          .nav {
            font-size: 12px;
            font-family: PingFangMedium;
            color: #EC6C01;
            line-height: 18px;
            border-radius: 4px;
            border: 1px solid #EC6C01;
            padding: 2px 8px;
            font-weight: 400;
            margin-right: 8px;
          }
        }
      }
      .newPrice {
        font-size: 12px;
        font-family: PingFangMedium;
        font-weight: bold;
        color: #EC6C01;
        line-height: 18px;
        margin-top: 11px;
        span {
          font-size: 18px;
        }
      }
    }
  }
  .warning {
    font-size: 16px;
    font-family: PingFangMedium;
    color: #999999;
    line-height: 19px;
  }
  .connector {
    position: absolute;
    right: -12.5px;
    top: 109px;
    .icon {
      width: 25px;
      height: 25px;
      font-size: 25px;
      color: #15b7dd;
    }
  }
}
.rightArrow {
  position: absolute;
  left: -12.5px;
  top: 109px;
  width: 25px;
  height: 25px;
}
.buyBox {
  text-align: center;
  width: 257px;
  padding-top: 74px;
  .title {
    font-size: 25px;
    font-family: PingFangMedium;
    color: #333333;
    line-height: 25px;
    letter-spacing: 2px;
    margin-bottom: 41px;
  }
  .price {
    height: 58px;
    text-align: center;
    .new {
      font-size: 18px;
      font-family: PingFangMedium;
      color: #EC6C01;
      line-height: 18px;
      span {
        font-size: 31px;
      }
    }
    .old {
      margin-top: 2px;
      font-size: 16px;
      font-family: PingFangMedium;
      color: #666666;
      line-height: 19px;
    }
  }
  .button {
    cursor: pointer;
    background: #15b7dd;
    box-shadow: 0px 5px 5px 1px rgba(206, 206, 206, 0.25);
    border-radius: 26px 26px 26px 26px;
    padding: 4px 36px;
    font-size: 23px;
    font-family: PingFangMedium;
    color: #ffffff;
    line-height: 34px;
    .icon {
      color: #ffffff;
      font-size: 23px;
      margin-right: 6px;
    }
  }
}
::v-deep.swiper-container {
  // margin: 0;
  width: 100%;
}
::v-deep.swiper-button-next {
  position: absolute;
  right: 0px;
  top: 187px;
  width: 40px;
  height: 40px;
  background-image: url("~@/assets/image/knowledgeMall/circularright.png");
  background-repeat: no-repeat;
  background-size: 40px;
  // img {
  //   width: 40px;
  //   height: 40px;
  //   position: relative;
  //   border-bottom-left-radius: 22px;
  //   border-top-left-radius: 22px;
  // }
}
.swiper-button-next:hover {
  background-image: url("~@/assets/image/knowledgeMall/circularright2.png");
}
::v-deep.swiper-button-next:after {
  opacity: 0;
}
::v-deep.swiper-button-prev:after {
  opacity: 0;
}
::v-deep.swiper-button-prev {
  position: absolute;
  left: 0;
  top: 187px;
  width: 40px;
  height: 40px;
  background-image: url("~@/assets/image/knowledgeMall/circularleft.png");
  background-repeat: no-repeat;
  background-size: 40px;
  // img {
  //   width: 40px;
  //   height: 40px;
  //   position: relative;
  //   border-bottom-left-radius: 22px;
  //   border-top-left-radius: 22px;
  // }
}
.swiper-button-prev:hover {
  background-image: url("~@/assets/image/knowledgeMall/circularleft2.png");
}
.industryitem {
  position: relative;
  .shopItem {
    text-align: center;
    .name {
      width: 300px;
      margin: 0 auto;
      text-align: left;
    }
    .oldPrice {
      width: 300px;
      margin: 0 auto;
      text-align: left;
    }
  }
}
.main-box {
  padding-top: 80px;
  padding-bottom: 60px;
  .banner {
    background: #fafafa;
    border-radius: 4px;
    padding: 40px 24px;
    // display: flex;
    .item {
      display: flex;
      .icon {
        margin: 77px 30px 0;
      }
    }
    .shopItem {
      .img {
        width: 300px;
        height: 260px;
      }
      .shopintroduce {
        width: 300px;
        height: 133px;
        margin: 0 auto;
        border-radius: 0 0 5px 5px;
        box-sizing: border-box;
        border: 1px solid #15b7dd;
        padding: 12px 24px 20px;
        .name {
          font-size: 18px;
          font-family: PingFangMedium;
          color: #333333;
          line-height: 27px;
        }
        .introduce {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 7px;
          .label {
            font-size: 14px;
            font-family: PingFangMedium;
            color: #1e97b4;
            line-height: 21px;
          }
          .navs {
            font-size: 12px;
            font-family: PingFangMedium;
            color: #EC6C01;
            line-height: 18px;
            border-radius: 4px;
            border: 1px solid #EC6C01;
            padding: 2px 8px;
            font-weight: 400;
          }
        }
        .price {
          display: flex;
          align-items: center;
          margin-top: 16px;
          .new {
            font-size: 14px;
            font-family: PingFangMedium;
            color: #EC6C01;
            line-height: 21px;
            span {
              font-size: 20px;
            }
          }
          .old {
            font-size: 14px;
            font-family: PingFangMedium;
            text-decoration: line-through;
            color: #999999;
            line-height: 21px;
            margin-left: 9px;
          }
        }
      }
    }
  }
  .moduletitle {
    padding: 10px 40px;
    margin-top: 40px;
    font-size: 24px;
    font-family: PingFangMedium;
    font-weight: bold;
    color: #15b7dd;
    line-height: 28px;
    display: flex;
    align-items: center;
    background: #f8f8f8;
    span {
      background-color: #15b7dd;
      height: 19px;
      width: 4px;
      border-radius: 7px;
      margin-right: 12px;
    }
  }
  .content {
    margin-top: 24px;
    padding: 40px;
    border-radius: 5px;
    background: #ffffff;
    display: flex;
    .shopimg {
      .img {
        width: 496px;
        height: 496px;
        border-radius: 5px;
      }
      @media screen and (max-width:16000px) {
        .img{
          width: 426px;
          height: 426px;
        }
      }
      .imgList {
        display: flex;
        margin-top: 12px;
        .active {
          border: 1px solid #15b7dd;
        }
        img {
          width: 60px;
          height: 60px;
          margin-right: 16px;
          border-radius: 2px;
          box-sizing: border-box;
          cursor: pointer;
        }
        img:last-child {
          margin: 0;
        }
      }
    }
    .shopDetail {
      margin-left: 30px;
      flex: 1;
      .name {
        font-size: 20px;
        font-family: PingFangMedium;
        font-weight: bold;
        color: #000000;
        line-height: 30px;
        span {
          display: inline-block;
          width: 44px;
          height: 23px;
          text-align: center;
          background: rgba(21, 183, 221, 0.12);
          border-radius: 4px 4px 4px 4px;
          margin-right: 8px;
          font-size: 14px;
          font-family: PingFangMedium;
          font-weight: bold;
          color: #15b7dd;
          line-height: 23px;
        }
      }
      .describe {
        margin-top: 16px;
        font-size: 14px;
        font-family: PingFangMedium;
        font-weight: 500;
        color: #1e97b4;
        line-height: 21px;
      }
      .introduce {
        padding: 24px 16px 0 16px;
        font-size: 16px;
        font-family: PingFangMedium;
        color: #666666;
        line-height: 24px;
      }
      .step-num {
        padding: 14px 16px;
        font-size: 14px;
        font-family: PingFangMedium;
        color: #333333;
        display: flex;
        align-items: center;
        .count {
          width: 108px;
          height: 41px;
          display: flex;
          align-items: center;
          padding: 0 6px;
          border-radius: 2px;
          border: 1px solid #ebebeb;
          .icon {
            font-size: 14px;
            color: #666666;
          }
          ::v-deep.ant-input-number-handler-wrap {
            display: none;
          }
          ::v-deep.ant-input-number {
            height: 20px;
            width: 77px;
            border: 0;
          }
          ::v-deep.ant-input-number-input {
            height: 20px;
            text-align: center;
          }
          ::v-deep.ant-input-number:focus {
            box-shadow: none !important;
          }
        }
      }
      .classDate {
        margin-top: 24px;
        margin-left: 16px;
        display: flex;
        .title {
          width: 71px;
          font-size: 14px;
          font-family: PingFangMedium;
          color: #666666;
          line-height: 21px;
        }
        .courseList {
          flex: 1;
          display: flex;
          flex-wrap: wrap;
          .courseItem {
            position: relative;
            padding: 18px;
            box-shadow: 0px 4px 10px 1px rgba(93, 131, 167, 0.15);
            display: flex;
            width: 324px;
            margin-left: 16px;
            margin-bottom: 16px;
            img {
              width: 60px;
              height: 60px;
            }
            .courseDateList {
              width: 624px;
              position: absolute;
              top: 90px;
              left: 0;
              background-color: #ffffff;
              z-index: 5;
              box-shadow: 0px 4px 10px rgba(93, 131, 167, 0.15);
              padding: 26px 16px 20px;
              display: flex;
              flex-wrap: wrap;
              .courseDateItem:nth-child(3n + 2) {
                margin: 0;
              }
              .courseDateItem {
                width: 186px;
                padding: 5px 17px;
                font-size: 14px;
                font-family: PingFangMedium;
                font-weight: 400;
                color: #333333;
                line-height: 28px;
                border-radius: 4px;
                margin-right: 17px;
                border: 1px solid #ebebeb;
                .top {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  .name {
                    width: 85px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 28px;
                  }
                  .num {
                    span {
                      color: #15b7dd;
                    }
                  }
                }
              }
              .active {
                color: #ffffff;
                background: #15b7dd;
                .top {
                  .name {
                    color: #ffffff;
                  }
                  .num {
                    span {
                      color: #ffffff;
                    }
                  }
                }
              }
            }
          }
        }
        .classList {
          flex: 1;
          display: flex;
          flex-wrap: wrap;
          .classItem {
            cursor: pointer;
            width: 225px;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid #ebebeb;
            margin-right: 16px;
            margin-bottom: 16px;
            padding: 5px 0;
            text-align: center;
            .classname {
              font-size: 14px;
              font-family: PingFangMedium;
              color: #333333;
              line-height: 28px;
            }
            .time {
              font-size: 14px;
              font-family: PingFangMedium;
              color: #333333;
              line-height: 28px;
            }
          }
          @media screen and (max-width:16000px) {
            .classItem{
              width: 181px;
            }
          }
          .classItem:nth-child(3n) {
            margin-right: 0;
          }
        }
      }
      .bayNote{
        margin-top: 24px;
        .funcBtn{
          cursor: pointer;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #15B7DD;
          text-decoration: underline;
        }
      }
      .buybutton {
        width: 142px;
        height: 46px;
        background: #15b7dd;
        border-radius: 23px;
        margin-top: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        margin-left: 16px;
        &:hover::before {
          left: 100%;
        }
        p {
          margin-left: 5px;
        }
      }
      .buybuttond{
        width: 142px;
        height: 46px;
        border-radius: 23px;
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background: #f5f5f5;
        color: #666666;
        overflow: hidden;
        cursor: pointer;
        margin-left: 16px;
        &:hover::before {
          left: 100%;
        }
        p {
          margin-left: 5px;
        }
      }
      .buybutton::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: -100%;
        background: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, .5), transparent);
        transition: all .5s;
      }
      .isBuy{
        cursor: initial;
        background: #31BCDD;
        opacity: 0.5;
      }
      .showAll {
        margin-bottom: 24px;
        margin-left: 71px;
        text-align: center;
        font-size: 14px;
        font-family: PingFangMedium;
        color: #999999;
        line-height: 21px;
        span {
          cursor: pointer;
        }
        .icon {
          margin-left: 4px;
        }
      }
      .shopMessage {
        margin-top: 20px;
        padding: 30px 16px;
        background: rgba(21, 183, 221, 0.05);
        position: relative;
        .equity{
          margin-top: 24px;
          font-size: 14px;
          color: #666666;
          p{
            display: inline-block;
          }
          a{
            color: #999999;
          }
        }
        .studyNum {
          position: absolute;
          right: 27px;
          top: 30px;
          .num {
            font-size: 16px;
            font-family: PingFangMedium;
            font-weight: 500;
            color: #15b7dd;
            line-height: 24px;
            text-align: right;
          }
          .font {
            font-size: 14px;
            font-family: PingFangMedium;
            font-weight: 500;
            color: #666666;
            line-height: 21px;
          }
        }
        .title {
          width: 72px;
          font-size: 14px;
          font-family: PingFangMedium;
          color: #666666;
          line-height: 21px;
        }
        .price {
          display: flex;
          align-items: center;
          // padding-top: 15px;
          .new {
            font-family: PingFangMedium;
            color: #EC6C01;
            line-height: 42px;
            font-size: 14px;
            margin-top: -5px;
            span {
              font-size: 28px;
            }
            .new_point {
              font-size: 14px;
            }
          }
          .newFree {
            font-size: 24px;
            margin-top: -9px;
          }
          .old {
            font-size: 16px;
            font-family: PingFangMedium;
            text-decoration: line-through;
            color: #999999;
            line-height: 21px;
            margin-left: 9px;
          }
        }
        .navs {
          // display: flex;
          // justify-content: space-between;
          // align-items: center;
          margin-top: 24px;
          word-wrap: break-word;
          word-break: normal;
          line-height: 21px;
          position: relative;
          .couponList{
            position: absolute;
            z-index: 2;
            top: 26px;
            left: 90px;
            background: #FFFFFF;
            border-radius: 5px;
            box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.1600);
            padding: 30px 20px 16px;
            .coupon{
              width: 322px;
              margin-bottom: 16px;
              padding-right: 15px;
              height: 80px;
              display: flex;
              align-items: center;
              background-image: url(~@/assets/image/knowledgeMall/coupon_1.png);
              background-size: 100% 100%;
              .couponLeft{
                padding-right: 20px;
                width: 93px;
                text-align: center;
                .price{
                  display: flex;
                  justify-content: center;
                  align-items: flex-end;
                  font-size: 16px;
                  font-family: PingFangRegular;
                  font-weight: 300;
                  color: #3A3330;
                  line-height: 37px;
                  span{
                    font-size: 30px;
                    line-height: 45px;
                    font-family: PingFangMedium;
                  }
                }
                .condition{
                  margin-top: -5px;
                  width: 80px;
                  font-size: 10px;
                  font-family: PingFangRegular;
                  font-weight: 400;
                  color: #3A3330;
                  line-height: 15px;
                  transform: scale(0.9);
                }
              }
              .couponRight{
                flex: 1;
                .couponName{
                  margin-left: -10px;
                  font-size: 14px;
                  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                  font-weight: 500;
                  color: #333333;
                  line-height: 14px;
                }
                .useTime{
                  margin-left: -10px;
                  margin-top: 7px;
                  font-size: 12px;
                  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                  font-weight: 400;
                  color: #999999;
                  line-height: 8px;
                  transform: scale(0.9);
                }
                .scope{
                  margin-top: 8px;
                  font-size: 10px;
                  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                  font-weight: 400;
                  color: #842C17;
                  line-height: 10px;
                }
              }
              .couponBtn{
                cursor: pointer;
                background: linear-gradient(134deg, #FFE4C2 0%, #FFD39F 100%);
              }
              .couponStyle{
                background: linear-gradient(134deg, #FFE4C2 0%, #FFD39F 100%);
              }
              .couponBtn,
              .couponStyle{
                text-align: center;
                width: 60px;
                height: 25px;
                border-radius: 2px;
                font-size: 14px;
                font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                font-weight: 500;
                color: #333333;
                line-height: 25px;
              }
            }
            .coupon:last-child{
              margin-bottom: 0;
            }
            .geted{
              opacity: 0.5;
              // background-image: url(~@/assets/image/knowledgeMall/coupon_2.png);
            }
          }
          span {
            display: inline-block;
          }
          .nav {
            font-size: 12px;
            font-family: PingFangMedium;
            color: #EC6C01;
            line-height: 18px;
            border-radius: 4px;
            border: 1px solid #EC6C01;
            padding: 2px 8px;
            font-weight: 400;
            margin-right: 8px;
          }
          .button {
            margin-left: 20px;
            font-size: 14px;
            font-family: PingFangMedium;
            color: #333333;
            line-height: 21px;
            padding-left: 20px;
            border-left: 1px dashed #999999;
            cursor: pointer;
          }
        }
        .giftList {
          display: flex;
          align-items: top;
          margin-top: 24px;
          .giftItem {
            display: flex;
            /deep/.swiper-container {
              width: 690px;
              @media screen and (max-width:16000px) {
                width: 580px;
              }
              .swiper-slide {
                cursor: pointer;
                display: flex;
                width: 120px;
              }
              .swiper-slide:last-child {
                margin-right: 50px;
              }
              .swiper-button-next {
                position: absolute;
                right: 0px;
                top: 20px;
                width: 22px;
                height: 42px;
                background-image: none;
                background-repeat: no-repeat;
                background-size: 22px;
                background-position: center;
                background-color: #f5f5f5;
                border-radius: 5px;
                .icon {
                  width: 10px;
                  height: 18px;
                  margin-left: 17px;
                  margin-top: 3px;
                  color: #999999;
                }
              }
              .swiper-button-next:hover {
                background-image: none;
                background-color: #15b7dd;
                .icon {
                  color: #ffffff;
                }
              }
              .swiper-button-next:after {
                opacity: 0;
              }
              .swiper-button-prev:after {
                opacity: 0;
              }
              .swiper-button-prev {
                position: absolute;
                left: 0;
                top: 20px;
                width: 22px;
                height: 42px;
                background-image: none;
                background-repeat: no-repeat;
                background-size: 22px;
                background-position: center;
                background-color: #f5f5f5;
                border-radius: 5px;
                .icon {
                  width: 10px;
                  height: 18px;
                  margin-left: 17px;
                  margin-top: 3px;
                  color: #999999;
                }
              }
              .swiper-button-prev:hover {
                background-image: none;
                background-color: #15b7dd;
                .icon {
                  color: #ffffff;
                }
              }
            }

            img {
              width: 40px;
              height: 40px;
            }
            .message {
              width: 68px;
              margin: 0 16px 0 8px;
              .giftName {
                font-size: 14px;
                font-family: PingFangMedium;
                color: #333333;
                line-height: 21px;
              }
              .count {
                margin-top: 5px;
                font-size: 12px;
                font-family: PingFangMedium;
                color: #333333;
                line-height: 18px;
              }
            }
          }
          .giftItemSwiper {
            /deep/.swiper-container {
              box-sizing: border-box;
              width: 690px;
              @media screen and (max-width:16000px) {
                width: 580px;
              }
              .swiper-wrapper {
                width: 600px;
                margin-left: 25px;
              }
            }
          }
        }
      }
    }
  }
}
.tabs-box {
  margin-top: 46px;
  background-color: #fff;
  padding: 75px 40px 40px;
  margin-bottom: 50px;
  position: relative;
  .tabs {
    height: 56px;
    background-color: #f8f8f8;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    // padding: 0 20px;

    .tabsdiv {
      margin-right: 40px;
      // padding: 10px 20px;
      width: 182px;
      height: 64px;
      font-size: 20px;
      font-family: PingFangMedium;
      position: relative;
      color: #666;
      display: flex;
      align-items: center;
      justify-content: center;
      // transition: all 0.3s;
    }
    .a1 {
      background-image: url("../../assets/image/curriculum/20220607-134028.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      // font-size: 24px;
      color: @theme;
    }
    .a2 {
      background-image: url("../../assets/image/curriculum/20220506-144134.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      // font-size: 20px;
      color: @theme;
    }
  }
}
.contenttab {
  overflow: hidden;
  /deep/img {
    max-width: 100%;
  }
  .material{
    transition: all 0.5s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FAFAFA;
    border-radius: 6px;
    padding: 17px 28px 17px 24px;
    margin-bottom: 20px;
    .mess,.lookBtn{
      display: flex;
      align-items: center;
      p{
        margin-right: 6px;
      }
    }
    .mess{
      .materialIcon{
        width: 14px;
        height: 14px;
        margin-right: 5px;
      }
      .courseName{
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
        margin-right: 5px;
      }
      .courseIcon{
        width: 32px;
        height: 17px;
        margin-top: 2px;
      }
      .sectionName{
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 18px;
        margin-top: 2px;
      }
    }
    .lookBtn{
      cursor: pointer;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #C1C1C1;
      line-height: 21px;
      .icon{
        font-size: 10px;
      }
    }
  }
  .material:hover {
    position: relative;
    z-index: 2;
    box-shadow: 0px 4px 10px 1px rgba(93, 131, 167, 0.15);
  }
}
.teacherName {
  font-size: 14px;
  font-family: PingFangMedium;
  color: #666666;
  line-height: 21px;
}
</style>
